import React from 'react';
import Seo from '../../components/Seo';
import MarginContainer from '../../components/MarginContainer';
import PageContentWrapper from '../../components/pageElements/PageContentWrapper';
import useCommonTitles from '../../components/useCommonTitles';
import Header from './header';
import Body from './body';
import QandA from './qanda';

function ProductsPage() {

  return (
    <>
      <Seo title="NesisAI" />
      <PageContentWrapper>
        <MarginContainer>
        {/* <img src='https://raw.githubusercontent.com/ametnes/nesis/main/nesis/frontend/client/src/images/Nesis.svg' width={"30%"}
            style={{
            marginTop: '15px',
            display: 'block'
            }} /> */}
          <Header/>
          <Body/>
          <QandA/>
        </MarginContainer>
      </PageContentWrapper>
    </>
  );
}

export default ProductsPage;
